<template>
  <Modal v-model:open="uploadFileModalState" :title="$t('Company.AttachFile')" :trigger="trigger" footer-alignment="center" class=" max-h-[700px]">

    <div class="h-full flex flex-col justify-center">
      <div v-show="displayPreview" class="flex flex-col justify-start mb-4 cursor-pointer">
        <Txt @click="handleUploadButton"> {{ $t('General.DragAndDropUploadLabel') }} </Txt>
        <Txt extra-small>{{ allowedTypesText + '. ' }} {{ $t('General.FileUploadSize', { size: fileSizesLimitLabel }) }}</Txt>
      </div>

      <div class="dropzone bg-white" @dragover.prevent="handleDragOver" @drop.prevent="handleUpload">
        <input :id="fileLabel" :ref="fileLabel+'Input'" :name="fileLabel" type="file" :accept="allowedTypesArray" class="sr-only" :multiple="allowMultiple" @change="handleUpload" />

        <div v-show="displayPreview" class="preview w-full flex flex-1 flex-wrap justify-center gap-2">
          <div v-for="file in filePreview" :key="fileLabel+file.name" class="preview-item">
            <div class=" h-3/5">

              <!-- TODO Support preview for other file types -->
              <img v-if="file.type.includes('image')" :src="file.src" :alt="file.name" class=" h-4/5 w-auto">
              <embed v-else-if="file.type == 'application/pdf'" :src="file.src" :type="file.type" width="100%" height="80%" />
              <Icon v-else name="file" :size="150" class="text-gray-200 h-4/5 w-auto" />
            </div>
            <div>
              <Txt small class="mb-2">{{ shortenFileName(file.name) }}</Txt>
              <Txt extra-small>{{ file.size }}</Txt>
            </div>
            <CGButton variant="outline" color="accent" @click="removeItem(file)">
              <Icon name="close" />
            </CGButton>
          </div>
        </div>

        <div v-show="!(displayPreview)" class="w-full h-full border rounded-lg cursor-pointer">
          <label :ref="fileLabel" :for="fileLabel">
            <Icon name="attachment" :size="100" class="text-gray-200 my-6" aria-hidden="true" />
            <div class="leading-8 space-y-2 mb-6 py-4">
              <Txt large>{{ $t('General.DragAndDropUploadLabel') }}</Txt>
              <Txt small>{{ allowedTypesText + '. ' }} {{ $t('General.FileUploadSize', { size: fileSizesLimitLabel }) }}</Txt>
            </div>
          </label>
        </div>
      </div>

      <Txt v-if="errorMessage" class=" text-red-500 m-2" small>{{ errorMessage }}</Txt>

    </div>
    <template #footer>
      <CGButton variant="outline" @click="showUploadModal(false)">{{ $t('General.Cancel') }}</CGButton>
      <CGButton :disabled="!files || files.length === 0" @click="saveImage">{{ $t('General.Upload') }}</CGButton>
    </template>
  </Modal>
</template>

<script>
import { defineComponent, nextTick } from 'vue';

const fileMimes = [
  {
    ext: '',
    mime: 'text/plain',
  },
  {
    ext: '.png',
    mime: 'image/png',
  },
  {
    ext: '.jpg',
    mime: 'image/jpeg',
  },
  {
    ext: '.jpeg',
    mime: 'image/jpeg',
  },
  {
    ext: '.gif',
    mime: 'image/gif',
  },
  {
    ext: '.webp',
    mime: 'image/webp',
  },
  {
    ext: '.svg',
    mime: 'image/svg+xml',
  },
  {
    ext: '.pdf',
    mime: 'application/pdf',
  },
  {
    ext: '.html',
    mime: 'text/html',
  },
  {
    ext: '.csv',
    mime: 'text/csv',
  },
];
export default defineComponent({
  emits: ['submit'],

  props: {
    fileLabel: {
      type: String,
      default: () => '',
    },
    acceptedFormats: { //extensions
      type: [Array, String],
      default: () => undefined, //  ['.JPEG','.PNG', '.PDF']
    },
    maxSize: { // File
      type: [Number, String],
      default: 1048576, // 'bytes' || 'Bytes', '1 KB', 'MB', 'GB'
    },
    allowMultiple: {
      type: Boolean,
      default: false,
    },
    maxFileCount: {
      type: Number,
      default: undefined,
    },
    trigger: {
      default: null
    },

  },

  data() {
    return {
      files: [],
      totalSizePercent: 0,
      totalSize: 0,
      showPreview: false,
      uploadFileModalState: false,
      errorMessage: '',
      filePreview: []
    }
  },

  computed: {
    allowedTypesArray() {
      if (this.acceptedFormats) {
        return (typeof this.acceptedFormats == 'string') ? this.acceptedFormats.toLowerCase().split(/\s*,\s*/): this.acceptedFormats.map(ext=>ext.trim().toLowerCase()) 
      }
      return []
    },
    allowedTypesText() {
      return this.allowedTypesArray.length && this.allowedTypesArray.join(', ').toUpperCase() || ''
    },
    allowedTypes() {
      return this.allowedTypesArray.length && fileMimes.filter(mime=>this.allowedTypesArray.includes(mime['ext'])).map(type=>type.mime) || []
    },
    maxFileSize() {
      return this.calculateBytes(this.maxSize);
    },
    fileSizesLimitLabel() {
      return this.formatFileSize(this.maxSize)
    },
    totalFileSize() {
      return this.files.length && this.files.reduce((acc, file) => acc + file.size, 0) || 0;
    },
    displayPreview() {
      return this.showPreview && this.filePreview.length
    }
  },

  methods: {
    handlePreviewFile(file) {
      if (!this.allowMultiple) this.filePreview = [];
      const reader = new FileReader();
      reader.onload = () => {
        this.filePreview.push({ src: reader.result, name: file.name, size: this.formatFileSize(file.size), type: file.type })
      };
      reader.readAsDataURL(file);
    },

    handleDragOver(e) {
      e.preventDefault();
    },

    handleUpload(e) {
      e.preventDefault()
      if (!(window.FileReader)) {
        this.errorMessage = this.$t('General.UploadNotSupported')
        return
      }
      const filesArray = e.target.files ? Array.from(e.target.files) : e.dataTransfer.files ? Array.from(e.dataTransfer.files) : []
      if (!filesArray.length) return

      const uploadedFiles = !this.allowMultiple ? [filesArray[0]] : filesArray
      const totalFileSize = this.totalFileSize + uploadedFiles.reduce((acc, file) => acc + file.size, 0)

      const invalidFileType = uploadedFiles.some(file=>!(this.allowedTypes.includes(file.type) || this.allowedTypesArray.includes('.' + file.name.split('.').slice(-1))))
      const invalidFileSize = uploadedFiles.length && uploadedFiles.some((f) => f.size > this.maxFileSize)
      const maxFileExceeded = this.allowMultiple && this.maxFileCount && this.files.length + uploadedFiles.length > this.maxFileCount
      const maxTotalExceeded = this.allowMultiple && totalFileSize >= this.maxFileSize;


      this.errorMessage = maxFileExceeded ? this.$t('General.FileMaxCountError', { count: this.maxFileCount }) :
      invalidFileSize ? this.$t('General.FileTooLargeError', { size: this.fileSizesLimitLabel }) : 
      invalidFileType ? this.$t('General.FileFormatUnsupported', { types: this.allowedTypesText }) : 
      maxTotalExceeded ? this.$t('General.FileTooLargeError', { size: this.MultipleFileSizesError }) : ''

      if (!this.allowMultiple) this.files = [];
      if (this.errorMessage == '') {
        for (const iFile of uploadedFiles) {
          this.handlePreviewFile(iFile);
          this.files.push(iFile);
        }
      }
      this.showPreview = true
    },

    handleUploadButton() {
      this.$refs[this.fileLabel].click()
    },
    removeItem(file) {
      this.filePreview.splice(this.filePreview.indexOf(file), 1)
      const removedFile = this.files.find(f => f.name == file.name)
      this.files.splice(this.files.indexOf(removedFile), 1)
      
      const fileInputEl = this.$refs[this.fileLabel+'Input'];
      fileInputEl.value = '';

    },
    showUploadModal(value) {
      this.uploadFileModalState = value
      nextTick(() => {
        if (value) {
          this.filePreview = []
          this.files = []
          this.errorMessage = ''
          const fileInputEl = this.$refs[this.fileLabel+'Input'];
          fileInputEl.value = '';
        }
      })
    },
    calculateBytes(fileSize) {
      if (typeof fileSize === 'string') {
        const match = fileSize.match(/^(\d+)([a-zA-Z ]*)$/);
        if (match) {
          fileSize = parseInt(match[1]);
          const unit = match[2].toLowerCase();

          if (unit === '' || unit.includes('bytes')) {
            fileSize;
          } else if (unit.includes('kb')) {
            fileSize *= 1024;
          } else if (unit.includes('mb')) {
            fileSize *= 1024 * 1024;
          } else if (unit.includes('gb')) {
            fileSize *= 1024 * 1024 * 1024;
          }
        }
      }
      return fileSize
    },
    formatFileSize(fileSize) {
      const byteSize = this.calculateBytes(fileSize)
      const sizes = ['Bytes', 'KB', 'MB', 'GB'];
      const i = Math.floor(Math.log(byteSize) / Math.log(1024));
      let result = (byteSize / Math.pow(1024, i)).toFixed(2);
      if (Number.isInteger(parseFloat(result))) {
        result = parseInt(result);
      }
      return result + ' ' + sizes[i];
    },
    saveImage() {
      this.showUploadModal(false)
      this.$emit('submit', { fileLabel: this.fileLabel, fileList: this.files }); // Subject to change
    },
    shortenFileName(fileName){
      const maxLength = 20
      if (fileName.length > maxLength) {
        const firstPart = fileName.substring(0, ((maxLength / 2) - 2));
        const lastPart = fileName.substring(fileName.length - ((maxLength / 2) + 1));
        fileName = firstPart + '...' + lastPart;
      }
      return fileName
    }
  },
});
</script>


<style lang="scss" scoped>
.dropzone {
  & * {
    @apply mx-auto;
    @apply text-center;
  }
}

.preview-item {
  @apply space-y-3 p-4;
  @apply border rounded-lg;
  @apply w-52 h-80 max-w-[50%];
}
</style>

